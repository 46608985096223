











import Vue from 'vue'
import PopupNoticeMweb from '~/components/atoms/PopupNoticeMweb.vue'
import DownloadModal from '~/components/organisms/LandingPage/DownloadModal/index.vue'
import LandingFooter from '~/components/organisms/LandingPage/Footer/index.vue'
import LandingHeader, { LandingHeaderProps } from '~/components/organisms/LandingPage/Header/index.vue'
import WhatsappFloatingButton from '~/components/organisms/LandingPage/WhatsappFloatingButton/index.vue'
import { playStoreLink } from '~/constants/LandingPage'
import { MainGlobalEvents } from '~/constants/pages/main-global-events'
import saveErrorLog from '~/utils/runner/save-error-log'

export default Vue.extend({
  name: 'MainLayout',
  components: {
    LandingFooter,
    LandingHeader,
    DownloadModal,
    PopupNoticeMweb,
    WhatsappFloatingButton,
  },
  data() {
    return {
      headerProps: {} as LandingHeaderProps,
      isModalDownloadActive: false,
      screen: '',
    }
  },
  async fetch() {
    if (process.server) {
      try {
        const { productCategoryClientApi } = await import('~/server/api/clients/product/category')
        const result = await productCategoryClientApi.getList()
        if (result) {
          this.$store.commit('setPublicProductCategories', result?.categories ?? [])
        }
      } catch (error) {
        await saveErrorLog(error)
      }
    }
  },
  computed: {
    isMobile(): boolean {
      return this.$viewport.match('mobile').valueOf()
    },
  },
  watch: {
    '$route.path': {
      handler() {
        this.headerProps = {} as LandingHeaderProps
        this.screen = ''
      },
    },
  },
  created() {
    this.$nuxt.$on(MainGlobalEvents.HEADER_CONFIG, (headerProps: LandingHeaderProps) => {
      this.headerProps = headerProps
    })

    this.$nuxt.$on(MainGlobalEvents.HEADER_DOWNLOAD_EVENT, () => {
      this.handleDownload()
    })

    this.$nuxt.$on(MainGlobalEvents.SCREEN_NAME, (screen: string) => {
      this.screen = screen
    })
  },
  beforeDestroy() {
    this.$nuxt.$off(MainGlobalEvents.HEADER_CONFIG)
    this.$nuxt.$off(MainGlobalEvents.HEADER_DOWNLOAD_EVENT)
    this.$nuxt.$off(MainGlobalEvents.SCREEN_NAME)
  },
  methods: {
    handleDownload() {
      if (this.isMobile) {
        window.open(playStoreLink, '_blank')
      } else {
        this.isModalDownloadActive = true
      }
    },
  },
})
