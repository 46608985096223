











// TODO: This file is temporary, once we decided what to do with global messaging, this can go
import { BlSnackbarMv } from '@bukalapak/bazaar-mweb'
import Vue from 'vue'
import { GlobalEvents } from '~/constants/pages/admin/global-events'

export default Vue.extend({
  name: 'PopupNoticeMweb',
  components: {
    BlSnackbarMv,
  },
  props: {
    actionText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showSnackbar: false,
      snackbarErrorStyling: false,
      snackbarMessage: '',
    }
  },
  created() {
    this.$nuxt.$on(GlobalEvents.MSG_SUCCESS, (message: string) => {
      this.snackbarMessage = message
      this.snackbarErrorStyling = false
      this.showSnackbar = true
    })
    this.$nuxt.$on(GlobalEvents.MSG_ERROR, (message: string) => {
      this.snackbarMessage = message
      this.snackbarErrorStyling = true
      this.showSnackbar = true
    })
  },
})
